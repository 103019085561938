<template>
  <el-dialog
      title="Product List"
      :fullscreen="true"
      :visible.sync="pdfdialogVisible"
  >
    <div style="margin-bottom: 100px; margin-top: 30px">
      <!--      <div ref="pdfContent" class="pdf-content" style="margin-bottom: 100px;height: 2000px;background-color: #005cc5">-->

      <div v-if="loading" class="loading-overlay">
        <p>{{ loadingMessage }}</p>
      </div>

      <div  class="pdf-content">
        <span class="pdfName"></span>

        <!-- 产品信息 -->
        <el-button
            id="exportBtn"
            type="primary"
            icon="el-icon-notebook-2"
            size="small"
            @click="exportPdf"
            title="Export Product Pdf"
            :disabled="!productList.length"
        >Export PDF</el-button>
        <div  ref="pdfContent" id="pdfContent">
          <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="24"
              :xl="24"
              class="pdfProductItem"
              v-for="(item) in productList"
              ref="pdfProductItem"
              :key="item.id"
          >
            <!--            :ref="'pdfProductItem'+index"-->
            <div class="imgBox">
              <div v-for="(img, index) in item.images" :key="index" style="width: 20%">
                <img v-if="index < 3" :src="img" :key="index"/>
              </div>
            </div>
            <div class="detailsInfo">
              <div class="topbox" style="margin-bottom: 20px">
                <span class="pdfName">{{ item.name }}</span>
                <span style="margin-left: 20px">{{ item.code }}</span>
              </div>
              <div class="pdfDescription">{{ item.describe }}</div>
              <div class="pdfPrice">
                <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10" class="content-left">
                  <div class="pdfTitle">Quantity Break</div>
                  <el-table :data="item.price_data">
                    <el-table-column label="Quantity">
                      <template slot-scope="scope">{{ scope.row.quantity }}+</template>
                    </el-table-column>
                    <el-table-column label="Price">
                      <template slot-scope="scope">${{ scope.row.delivered_price }}</template>
                    </el-table-column>
                  </el-table>
                </el-col>
                <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" class="content-right">
                  <div class="material" v-if="item.material_str">
                    <div class="pdfTitle">MATERIAL</div>
                    <div class="pdfContent">{{ item.material_str }}</div>
                  </div>
                  <div class="printing-mode" v-if="item.imprint_str">
                    <div class="pdfTitle">PRINTING MODE</div>
                    <div class="pdfContent">{{ item.imprint_str }}</div>
                  </div>
                  <div class="printing-size" v-if="item.imprint_area">
                    <div class="pdfTitle">PRINTING SIZE</div>
                    <div class="pdfContent">{{ item.imprint_area }}</div>
                  </div>
                  <div class="color" v-if="item.color">
                    <div class="pdfTitle">COLOR</div>
                    <div class="pdfContent">{{ item.color }}</div>
                  </div>
                </el-col>
              </div>
            </div>
          </el-col>
          <!--          <p>&nbsp;</p>-->
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { imgToBase } from "@/api/placard";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {login} from "@/api/login";
import 'jspdf-autotable';
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pdfdialogVisible: false,
      productList: [],
      loading: false,
      loadingMessage: '',
      isbase:false,
      zancunH: [],
      /*imagesLoaded: 0,
      totalImages: 0,*/
    };
  },
  mounted() {
  },
  methods: {
    // 将图片URL数组转换为Base64编码数组
    async imageUrlsToBase64(urls) {
      try {
        const urlString = urls.join(',');
        const res = await imgToBase({
          no: urlString,
          link: window.location.href,
        });
        return res.data;
      } catch (error) {
        return urls.map(() => null); // 返回一个与urls等长的数组，值为null，以便继续处理
      }
    },
    isSplit(nodes, index, pageHeight) {
      if (nodes[index].offsetTop + nodes[index].scrollHeight < pageHeight && nodes[index + 1] && nodes[index + 1].offsetTop + nodes[index + 1].scrollHeight > pageHeight) {
        return true; // 节点被分割
      } else {
        return false; // 节点未被分割
      }
    },
    async exportPdf() {
      this.loading = true;
      this.loadingMessage = 'Please wait while PDF is being exported...';
      // 将页面滚动条置顶
      document.body.scrollTop = 0;
      document.body.scrollLeft = 0;

      if (!this.isbase){
        const promises = [];
        this.productList.forEach((product) => {
          const imageUrls = product.images;
          const promise = this.imageUrlsToBase64(imageUrls).then((base64Images) => {
            // 确保 base64Images 数组与原来的 imageUrls 数组长度相同
            base64Images.forEach((base64Image, index) => {
              this.$set(product.images, index, base64Image);
            });
          });

          promises.push(promise);
        });
        // 等待所有 promises 完成
        await Promise.all(promises);
        this.isbase = true;
      }
      // 确保内容已加载完成
      await this.$nextTick();

      // 隐藏导出按钮
      var element = document.getElementById('exportBtn');
      if (element) {
        element.style.display = 'none';
      }

      // A4纸张宽度和高度
      const A4_WIDTH = 592.28;
      const A4_HEIGHT = 841.89;

      // 获取图片容器和页面高度
      let imageWrapper = document.getElementById('pdfContent');
      let scrollWidthWap = imageWrapper.scrollWidth
      // if(imageWrapper.scrollWidth<729) {
      //   scrollWidthWap = 729
      // }else {
      //   scrollWidthWap = imageWrapper.scrollWidth
      // }
      let pageHeight = (scrollWidthWap / A4_WIDTH) * A4_HEIGHT;
      // console.log(pageHeight)
      // 获取所有需要分割的节点
      let lableListID = imageWrapper.querySelectorAll('.pdfProductItem');
      // console.log(lableListID)
      // 初始化分页留白数组
      this.zancunH[0] = 0;

      // 遍历节点，判断是否需要分割并添加留白节点
      for (let i = 0; i < lableListID.length; i++) {
        // console.log(lableListID[i].offsetTop)
        // console.log(lableListID[i].scrollHeight)
        let multiple = Math.ceil((lableListID[i].offsetTop + lableListID[i].scrollHeight) / pageHeight);
        // console.log(multiple)
        if(imageWrapper.scrollWidth<729) {
              let divParent = lableListID[i].parentNode; // 获取节点的父节点
              // console.log(divParent)
              let newNode = document.createElement('div');
              newNode.className = 'emptyDiv';
              newNode.style.background = '#ffffff';
              let _H = multiple * pageHeight - (lableListID[i].offsetTop + lableListID[i].scrollHeight);
            // console.log(_H)
              this.zancunH[multiple] = _H;
              // console.log(this.zancunH)
                newNode.style.height = pageHeight - lableListID[i].scrollHeight -40 + 'px'; // 设置留白节点高度
                newNode.style.width = '100%';
              let next = lableListID[i].nextSibling; // 获取节点的下一个兄弟节点
              if (next) {
                divParent.insertBefore(newNode, next); // 将留白节点插入到下一个兄弟节点之前
              } else {
                divParent.appendChild(newNode); // 将留白节点添加到最后
              }
          

          }else {
            if (this.isSplit(lableListID, i, multiple * pageHeight)) {
              let divParent = lableListID[i].parentNode; // 获取节点的父节点
              // console.log(divParent)
              let newNode = document.createElement('div');
              newNode.className = 'emptyDiv';
              newNode.style.background = '#ffffff';
              let _H = multiple * pageHeight - (lableListID[i].offsetTop + lableListID[i].scrollHeight);
            // console.log(_H)
              this.zancunH[multiple] = _H;
              // console.log(this.zancunH)
        
                newNode.style.height = _H+50 + 'px'; // 设置留白节点高度
                newNode.style.width = '100%';
              
        
              let next = lableListID[i].nextSibling; // 获取节点的下一个兄弟节点
              if (next) {
                divParent.insertBefore(newNode, next); // 将留白节点插入到下一个兄弟节点之前
              } else {
                divParent.appendChild(newNode); // 将留白节点添加到最后
              }
            }
          }
      
      }
      // console.log(imageWrapper)

      // 使用html2Canvas进行截图
      html2canvas(imageWrapper, { // 使用html2Canvas进行截图
        allowTaint: true, // 允许跨域图片渲染
        useCORS: true, // 使用跨域资源
        scale: 2, // 图片放大倍数，提高清晰度
        dpi: 300, // 设置图片的 dpi（每英寸像素数）
        logging: true
      })
          .then((canvas) => {
            // 使用jsPDF生成PDF
            let pdf = new jsPDF('p', 'mm', 'a4'); // 创建A4大小的PDF
            let ctx = canvas.getContext('2d');
            let a4w = 190;
            let a4h = 270; // A4 大小，210mm x 297mm，四边各保留 10mm 的边距，显示区域 190x277，
            let imgHeight = Math.floor((a4h * canvas.width) / a4w);  // 按 A4显示比例换算一页图像的像素高度
            let renderedHeight = 0;

            // 按照A4大小进行分页渲染
            while (renderedHeight < canvas.height) {
              let page = document.createElement('canvas');
              page.width = canvas.width;
              page.height = Math.min(imgHeight, canvas.height - renderedHeight); // 可能内容不足一页
              // 用 getImageData 剪裁指定区域，并画到前面创建的 canvas 对象中
              page
                  .getContext('2d')
                  .putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0);
              pdf.addImage(page.toDataURL('image/jpeg', 1), 'JPEG', 10, 10, a4w, Math.min(a4h, (a4w * page.height) / page.width)); // 添加图像到页面，保留 10mm 边距
              renderedHeight += imgHeight;
              if (renderedHeight < canvas.height) pdf.addPage(); // 如果后面还有内容，添加一个空页
            }
            pdf.save('paperDownName'); // 下载PDF文件
          })
          .then(() => {
            // 下载成功提示
            this.loading = false
            var element = document.getElementById('exportBtn');
            if (element) {
              element.style.display = 'block';
            }
            const emptyDivEl = imageWrapper.querySelectorAll('.emptyDiv')
            emptyDivEl.forEach(function(element) {
              element.parentNode.removeChild(element);
            });
          })
          .catch((error) => {
            // 下载失败提示
            // console.error('PDF 文件保存失败:', error);
          });
    },

  },
};
</script>

<style>
.el-col {
  border-radius: 4px;
}
#pdfContent{
  display: flex;
  flex-wrap: wrap;
}
.pdfProductItem {
  font-size: 18px;
  border: solid 1px #aaa;
  padding: 40px;
  margin: 42px 0px;
}
.imgBox {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}
.imgBox img {
  padding: 10px;
  height: auto;
  max-height: 300px;
  max-width: 18vw;
}
.pdfName {
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
}
.pdfDescription {
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 1px;
}
.material {
  clear: both;
  margin-top: 20px;
}
.pdfPrice {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
}
.pdfPrice>div:first-child{
  margin-right: 30px;
}
.pdfPrice .el-table{
  font-size: 18px;
}
.pdfPrice .el-table thead{
  color: black;
  font-size: 20px;
  font-weight: 700;
}
.pdfTitle {
  font-weight: bold;
}
.pdfContent {
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}
.printing-mode {
  margin-top: 20px;
}
.printing-size {
  margin-top: 20px;
}
.pdf-content {
  background-color: white;
  padding: 20px;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
  z-index: 1000; /* 确保加载动画在最上层 */
}
@media only screen and (max-width: 768px){
  .imgBox{
    margin-bottom: 5px;
  }
  .pdfProductItem{
    padding: 10px;
    font-size: 15px;
    margin: 20px 0;
  }
  .pdf-content{
    padding: 0;
  }
  .imgBox img {
    max-width: 80%;
    height: 58px;
    padding: 0;
  }
  .pdfName {
    font-size: 20px;
  }
  .pdfPrice {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .pdfPrice>div:first-child{
    margin-right: 5px;
    font-size: 16px;
   
  }
  .pdfPrice .el-table{
    font-size: 15px;
  }
  .content-left {
    width: 60%;
  }
  .content-right{
   flex: 1;
  }
  .detailsInfo .topbox{
    margin-bottom: 5px !important;
  }
  .pdfDescription {
    line-height: 100%;
    font-size: 14px;
  }
  .pdfContent{
    line-height: 25px;
  }
  .el-table .el-table__cell{
    padding: 5px 0;
  }
  .printing-mode{
    margin-top: 0;
  }
}
@media only screen and (min-width: 769px ) and (max-width: 1024px){
  .pdfProductItem{
    padding: 10px;
  }
  .pdf-content{
    padding: 0;
  }
  .imgBox img {
    max-width: 95%;
  }
  .pdfName {
    font-size: 30px;
  }
  .pdfPrice>div:first-child{
    margin-right: 30px;
   
  }
  .pdfDescription {
    line-height: 150%;
  }
}


</style>
