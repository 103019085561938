import request from '@/utils/request'

export function catalogue(params) {
    return request({
      url: 'product/catalogue',
      method: 'get',
      params
    })
  }

export function exportExcel(data) {
    return request({
        responseType: 'blob', // 重点,responseType设置为 blob
        url: 'product/exportExcel',
        method: 'post',
        data
    })
}

export function exportPdf(data) {
    return request({
        responseType: 'blob', // 重点,responseType设置为 blob
        url: 'product/exportPdf',
        method: 'post',
        data
    })
}

export function imgToBase(data) {
    return request({
        url: 'product/imgToBase',
        method: 'post',
        data
    })
}