<template>
  <div>
    <div class="placard">
      <div class="mainBox" v-show="productList.length > 0">
        <div class="num">
         <div> Proposal #{{ no }}</div>
          <div class="data" style="margin: 10px 0px;">Created on {{ create_date }}&nbsp;&nbsp;•&nbsp;&nbsp;Expires on {{ expires_date }}</div>
        </div>
        <div class="shareBox">
          <el-button-group style="margin-left: 10px">
            <el-button type="primary" icon="el-icon-link" size="mini" title="Copy Web Link" @click="copyLink">Link</el-button>
<!--            <el-button type="primary" icon="el-icon-document" size="mini" title="Export Quote Sheet" @click="exportSheet">Sheet</el-button>-->
            <el-button type="primary" icon="el-icon-postcard" size="mini" title="Preview pdf file details" @click="pdfDetail">PDF</el-button>
          </el-button-group>
        </div>
        <div ref="pdfContent">
          <el-row>
            <el-col
                :xs="24"
                :sm="12"
                :md="8"
                :lg="6"
                :xl="4"
                class="cellItem"
                v-for="(item,index) in productList"
                :key="item.id"
            >
              <div class="productItem" @click="viewDetail(item)">
                <div class="index">
                  {{ index+1 }}
                </div>
                <div class="img">
                  <img :src="item.picture"/>
                </div>
                <div class="price" v-if="item.min_price == item.max_price">${{ item.min_price }}</div>
                <div class="price" v-else>${{ item.min_price }} — ${{ item.max_price }}</div>
                <div class="name">{{ item.name }}</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <Details ref="detailsDialog"></Details>
      <PdfView ref="pdfViewDialog"></PdfView>
    </div>
  </div>
</template>

<script>
import Details from "./details.vue";
import PdfView from "./pdf.vue";
import {catalogue, exportExcel} from "@/api/placard";

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import VueClipboard from "vue-clipboard2";
import Vue from "vue";
Vue.use(VueClipboard);

export default {
  components: {
    Details,PdfView
  },
  data() {
    return {
      prodictId: "",
      productList:[],
      no:'',
      create_date:'',
      expires_date:''
    };
  },
  mounted() {
    // createCatalogue({
    //   ids:this.$route.query.ids,
    //   token:localStorage.getItem('token'),
    //   percent:this.$route.query.percent,
    //   quantity:this.$route.query.quantity.split(',')
    // }).then(res=>{
    //   console.log(res)
    // })
    this.no=this.$route.query.no
    catalogue({
      no: this.no,
    }).then((res) => {
      this.productList=res.data.product_data
      this.expires_date=res.data.expires_date
      this.create_date=res.data.create_date
    });
  },
  methods: {
    handleClose(done) {
      done();
    },

    viewDetail(product) {
      this.dialogVisible = true;
      this.$refs.detailsDialog.dialogVisible = true;
      this.$refs.detailsDialog.productInfo = product;
    },
    /*复制当前网页的链接地址*/
    copyLink() {
      const currentUrl = window.location.href; // 获取当前页面的URL

      this.$copyText(currentUrl).then(e=>{
        this.$message.success('Copy link successful, you can paste');
      }, e=> {
        this.$message.error('Failed to copy the link, please copy manually');
      })
    },
    /*导出报价表-Sheet*/
    exportSheet() {
      this.no=this.$route.query.no
      exportExcel({
        no: this.no,
        link:window.location.href,
      }).then((res) => {
        const elink = document.createElement('a');
        elink.download = 'quote.xlsx';
        elink.style.display = 'none';
        const blob = new Blob([res], { type: 'application/x-msdownload' });
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
      });
    },
    /*打开pdf详情页*/
    pdfDetail() {
      this.dialogVisible = true;
      this.$refs.pdfViewDialog.pdfdialogVisible = true;
      this.$refs.pdfViewDialog.productList = this.productList;
    },
    handleCommand(command){
      /*复制当前网页的链接地址*/
      if (command == 'link') {
        const currentUrl = window.location.href; // 获取当前页面的URL
        this.$copyText(currentUrl).then(e=>{
          this.$message.success('Copy link successful, you can paste');
        }, e=> {
          this.$message.error('Failed to copy the link, please copy manually');
        })
      }
      /*导出报价表-Sheet*/
      if (command == 'sheet') {
        this.no=this.$route.query.no
        exportExcel({
          no: this.no,
        }).then((res) => {
          const elink = document.createElement('a');
          elink.download = 'quote.xlsx';
          elink.style.display = 'none';
          const blob = new Blob([res], { type: 'application/x-msdownload' });
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          document.body.removeChild(elink);
        });
      }
      /*导出产品数据-Pdf*/
      if (command == 'pdf') {
        const content = this.$refs.pdfContent;
        html2canvas(content).then(canvas => {
          const contentWidth = canvas.width
          const contentHeight = canvas.height
          const pageHeight = contentWidth / 592.28 * 841.89
          let leftHeight = contentHeight
          let position = 0
          const imgWidth = 595.28
          const imgHeight = 592.28 / contentWidth * contentHeight
          const pageData = canvas.toDataURL('image/jpeg', 1.0)
          const PDF = new jsPDF('', 'pt', 'a4')
          if (leftHeight < pageHeight) {
            PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
          } else {
            while (leftHeight > 0) {
              PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
              leftHeight -= pageHeight
              position -= 841.89
              if (leftHeight > 0) {
                PDF.addPage()
              }
            }
          }
          PDF.save('download.pdf')
        });
      }
    }
  },
};
</script>


<style lang="scss" scoped>
.placard {
  background-color: rgb(250 250 250);
  padding-top: 50px;
  font-size: 15px;
  .mainBox {
    // margin-top: 60px !important;
    max-width: 1170px;
    height: 1000px;
    margin: 0 auto;
    padding-top: 40px;
    background-color: rgb(255 255 255);
    color: #3C3C3C !important;
    padding: 80px 40px;
    .num {
      margin-left: 10px;
    }
    .productItem {
      border: 1px solid #f0f0f0;
      margin: 10px;
      /*padding: 20px 40px;*/
      cursor: pointer;
      .index {
        border: 1px solid #f0f0f0;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        font-weight: bolder;
      }
      .img {
        /*width: 219.5px;
        height: 219.5px;*/
        width: 100%; /* 设置容器的宽度为100% */
        padding-bottom: 50%; /* 高度为宽度的75%（可以调整成你需要的比例） */
        position: relative; /* 设置容器为相对定位 */
        overflow: hidden; /* 隐藏超出部分 */
      }
      .img img{
        /*width: 100%;
        height: 100%;*/
        position: absolute; /* 设置图片为绝对定位 */
        top: 0;
        left: 0;
        width: 100%; /* 图片宽度占满容器 */
        height: 100%; /* 图片高度占满容器 */
        object-fit: contain; /* 保持图片的宽高比，内容覆盖容器 */
        object-position: center; /* 将图片居中 */
      }
      .price {
        font-family: "Roboto", sans-serif;
        color: rgba(0, 0, 0, 0.87);
        text-align: center;
        line-height: 50px;
      }
      .name {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 48px;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        max-width: 100%;
      }
    }

    .shareBox {

    }
  }
}
</style>
<style scoped>
@media screen and (min-width: 1600px) {
  .el-col-lg-6 {
    width: 25%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .el-col-lg-6 {
    width: 33.3%;
  }
}
@media screen and (min-width: 800px) and (max-width: 1200px) {
  .el-col-lg-6 {
    width: 50%;
  }
}
</style>
